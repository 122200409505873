<template>
    <div>
        <!-- Start 404 Page  -->
        <div class="error-page-inner bg_color--4">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <div class="inner">
                            <h1 class="heading-title theme-gradient">Cleverwyz</h1>
                            <h3 class="sub-title">Work in Progress</h3>
                            <span>We are still building this website.</span>

                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <!-- End 404 Page  -->
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {};
    },
};
</script>